<template>
    <div class="row mx-0">
        <div class="col-7 px-0 border-right overflow-auto custom-scroll" style="height:calc(100vh - 147px);">
            <div class="row mx-0 mt-3">
                <img src="https://img.freepik.com/vector-gratis/personas-pie-cola-tienda_23-2148594615.jpg?w=2000" class="br-4 obj-cover ml-4 border" width="54" height="54" />
                <div class="col px-2">
                    <p class="text-muted2 f-14">
                        <strong class="f-17">
                            Estado:
                        </strong>
                        {{ detalle.estado===1 ? 'Activo': 'Inactivo' }}
                    </p>
                </div>
                <div class="col-12 px-4">
                    <p class="text-muted2 f-14 my-2">
                        <strong class="f-17">
                            Base de datos:
                        </strong>
                        {{ detalle.base_datos }}
                    </p>
                    <p class="text-muted2 f-14 my-2">
                        <strong class="f-17">
                            Dominio:
                        </strong>
                        https://<strong style="color:#FF612B;"> {{ detalle.dominio }}</strong>
                    </p>
                    <p class="text-muted2 f-17 f-600 mt-2">
                        Descripción:
                    </p>
                    <p class="f-14 text-muted2">
                        {{ detalle.descripcion }}
                    </p>
                </div>
            </div>
            <div class="d-middle mx-0 mt-5 mb-3">
                <div class="col px-4">
                    <span class="text-muted2 f-600 f-15"> Administradores del proyecto </span>
                </div>
                <div class="col-auto px-2">
                    <el-tooltip placement="bottom" content="Asignar permisos" effect="light">
                        <div class="button-administrar d-middle-center br-8 cr-pointer" style="width:30px;height:30px;" @click="$router.push({name: 'manager.asignar-permisos'})">
                            <i class="icon-key-variant" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto px-0">
                    <el-tooltip slot="reference" class="item" effect="light" content="Agregar administrador" placement="bottom">
                        <div class="d-middle mx-0 bg-grr-general text-white mx-2 br-8 cr-pointer" style="width:30px;height:30px;" @click="agregarAdmin(null)">
                            <i class="icon-plus f-20" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div v-for="(user, idx) in userAdmin" :key="idx" class="mx-5 my-2 d-middle border p-1 bg-whitesmoke f-12 br-12" :disabled="user.estado">
                <div class="rounded-circle text-white mr-2 bg-general d-middle-center" style="width:30px;height:30px;">
                    {{ nombre_usuario(user.nombre) }}
                </div>
                <p class="tres-puntos"> {{ user.nombre }}</p>
                <i v-show="!user.estado" class="icon-eye-off" />
                <p class="ml-auto px-0"> {{ user.multicedis ? 'MC' : user.cedis }}</p>
                
                <div class="px-0">
                    <el-popover placement="bottom" width="150" trigger="hover">
                        <div class="row mx-0 p-1 hover-popover cr-pointer" @click="agregarAdmin(user)">
                            Editar
                        </div>
                        <div class="row mx-0 p-1 hover-popover cr-pointer" @click="modalEliminar(user.id)">
                            Eliminar
                        </div>
                        <div slot="reference" class="cr-pointer col-auto ml-auto">
                            <i class="icon-ellipsis-vert f-20" />
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>
        <div class="col-5 border-left custom-scroll overflow-auto" style="height:calc(100vh - 147px)">
            <div class="row mx-0 p-3">
                <div class="col text-muted2 f-15 f-600">
                    Lugar(es) de operación
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <el-radio-group v-model="detalle.cobertura" @change="cambiarCoberturaProyecto">
                    <el-radio :label="3">
                        Pais
                    </el-radio>
                    <el-radio :label="2">
                        Estado
                    </el-radio>
                    <el-radio :label="1">
                        Ciudad
                    </el-radio>
                </el-radio-group>
            </div>
            <!-- Pais -->
            <div class="row mx-0 mt-4 mb-2 justify-center">
                <div class="select-bandera">
                    <!-- <img src="https://i.pinimg.com/originals/ea/aa/8f/eaaa8fbaff9e086cec5d2e00ffad3c3b.png" width="26px" height="26px" class="position-absolute obj-cover z-1 br-50" style="top:30px;left:6px;" alt=""> -->
                    <label class="f-15 text-muted2"> Seleccionar el país </label>
                    <el-select ref="mypais" v-model="id_pais" class="w-100" filterable remote reserve-keyword placeholder="Seleccione o digite un país" :remote-method="remoteMethodPais" :loading="loading" @change="ingresarPais">
                        <el-option v-for="item in paises" :key="item.id" :label="item.pais" :value="item.id" :disabled="item.disabled" />
                    </el-select>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <div class="row mx-0 px-3">
                        <div v-for="(item, idx) in paisesGuardados" :key="idx" class="bg-gr-red px-2 br-12 d-middle-center text-white my-2 mx-1" style="height:32px;">
                            {{ item.pais }} <i class="icon-cancel cr-pointer" @click="eliminarCascadaLugar(item)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- Estado -->
            <hr class="border-bottom" />
            <template v-if="detalle.cobertura == 2"> 
                <estados v-for="item in paisesGuardados" :key="item.id_pais" :id-pais="item.id_pais" :pais="item.pais" />
            </template>
            <!-- Ciudad -->
            <template v-if="detalle.cobertura == 1"> 
                <div v-for="item in estadosGuardados" :key="item.id_pais">
                    <p class="pl-4">
                        <span class="f-600">{{ item.name_pais }}</span>
                        
                        <estados :id-pais="item.pais_id" :pais="item.name_pais" />
                    </p>
                    <ciudades v-for="info in item.estados" :key="info.id_estado" :id-estado="info.id_estado" :estado="info.estado" />
                    <hr class="border-bottom" />
                </div>
            </template>
        </div>
        <!-- Modales -->
        <modalAgregarAdministrador ref="modalAgregarAdministrador" />
        <modalConfirmar ref="modaleliminarUser" titulo="Eliminar administrador" icon="checked" mensaje="¿Desea eliminar este administador?" @guardar="eliminarUser" />
    </div>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

export default {
    components:{
        modalAgregarAdministrador: ()=> import('./partials/modalAgregarAdministrador'),
        estados: ()=> import('./components/estados'),
        ciudades: ()=> import('./components/ciudades')
    },
    data(){
        return{
            id_eliminar: null,
            loading: false,
            id_pais: null,
            paises:[],
        }
    },
    computed:{
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto',
            detalle: 'proyectos/proyectos/detalle',
            userAdmin: 'proyectos/proyectos/userAdmin',
            paisesGuardados: 'proyectos/proyectos/paisesGuardados',
            estadosGuardados: 'proyectos/proyectos/estadosGuardados'
        })
    },
    mounted(){
        this.limpiarVista()
        this.listar_paises()
    
    },
    methods: {
        agregarAdmin(info){
            this.$refs.modalAgregarAdministrador.toggle(info)
        },
        modalEliminar(id){
            this.id_eliminar = id
            this.$refs.modaleliminarUser.toggle()
        },
        async eliminarUser(){
            try {
                const {data} = await Proyectos.eliminarUserAdmin({id: this.id_eliminar, id_proyecto: this.id_proyecto})
                this.notificacion('Mensaje', 'Usuario eliminado', 'success')
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                
            } catch (e){
                this.error_catch(e)
            }
        },
        nombre_usuario(name){
            if(!name){
                return name
            }
            let array = name.split(' ')
            let ini = array[0][0]
            let text = ini.toUpperCase()
            if(array[1] !== undefined){
                let second = array[1][0].toUpperCase()
                text = `${ini}${second}`        
            }
            return text
        },
        async remoteMethodPais(texto){
            console.log('remoteMethodPais', texto);
            this.delay(this.listar_paises(texto))
        },
        async listar_paises(texto= null){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    texto 
                }
                this.loading = true
                const {data} = await Proyectos.buscarPaises(params)
                this.loading = false
                this.paises = data.paises.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async ingresarPais(){
            try {
                const enviar = {
                    id_proyecto: this.id_proyecto,
                    id_pais:  this.id_pais 
                }
                const {data} = await Proyectos.ingresarPais(enviar)
                this.notificacion('Mensaje', 'Pais ingresado', 'success')
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                // this.$refs.mypais.$children[0].$attrs.placeholder = 'Seleccione o digite un país'
                // this.$refs.mypais.query=null

                this.listar_paises()
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarCascadaLugar(item){
            try {

                const {data} = await Proyectos.eliminarLugarCascada(item.id, this.detalle.cobertura)
                this.notificacion('Mensaje', 'Lugar eliminado', 'success')
                this.$store.dispatch('proyectos/proyectos/action_detalle_proyecto', {id_proyecto: this.id_proyecto})
                this.listar_paises()

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarCoberturaProyecto(){
            try {
                const enviar = {
                    id_proyecto: this.id_proyecto,
                    cobertura:  this.detalle.cobertura 
                }
                const {data} = await Proyectos.cambiarCobertura(enviar)
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarVista(){
            this.id_eliminar = null
            this.loading = false
            this.id_pais = null
            this.paises = []
            this.$store.commit('proyectos/proyectos/set_paisesGuardados', [])
            this.$store.commit('proyectos/proyectos/set_estadosGuardados', [])
            const detalle = {
                id: null,
                nombre: null,
                tipo: 1,
                estado: 1,
                descripcion: null,
                base_datos: null,
                dominio: null,
                bucket_s3: null,
                cant_tiendas: null,
                tiendas_activas: null,
                prefijos: []
            }
            this.$store.commit('proyectos/proyectos/set_detalle', detalle)

        }
     
    }
}
</script>
<style lang="scss">
.input-edit{
    .el-input{
        .el-input__inner{
            border-radius: 20px;
        }
    }
}
.bg-white{
    background: #FFFFFF;
}
.select-bandera{
    .el-select{
        &.w-100{
            .el-input{
                &.el-input--suffix{
                    .el-input__inner{

                        text-align: center;
                    }
                }
            }
        }
    }
}
.select-pais{
    .el-select{
        &.w-100{
            .el-input{
                &.el-input--suffix{
                    &.is-focus{
                        .el-input__inner{
                            text-align: center;
                            border: 1px solid #FF612B !important;
                        }
                    }
                    .el-input__inner{
                            text-align: center;
                            border: 1px solid #FF612B !important;
                        }

                }
            }
        }
    }
}
.button-administrar{
    background-color: #5D5D5D;
    color: #ffffff;
}
</style>
